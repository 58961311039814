import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Swal from 'sweetalert2'
import Toolbar from '@/components/toolbar/toolbar';
export default {
    name: "Sale",
    data() {
        return {
            lists: [],
            lnglat: [],
            region_selected: '',
            region: [],
            province_selected: '',
            province: [],
            searchQuery: "",
            lists_sale: [],
            baseFront: this.$baseFront
        }
    },
    watch: {
        region_selected() {
            this.province_selected = ""
        }
    },
    methods: {
        async fetchMaster() {
            await this.$axios.get("/api/master/region")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.region = response.data.data
                    }
                })
            await this.$axios.get("/api/master/province")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.province = response.data.data
                    }
                })
        },
        async fetchSearch() {
            await this.$axios.get("/api/show/sale_channel")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.lists = response.data.data
                        this.lists = this.lists.sort((a, b) => a.sort - b.sort)
                        this.filteredSale()
                        console.log(this.lists);
                    }
                })
        },
        open(path) {
            window.open(path, '_blank');
        },
        filterProvince(value) {
            if (value === '') {
                return this.province;
            }
            return this.province.filter(item => item.ref_region_id == value);
        },
        filteredSale() {
            let sort = [];
            if(this.$lang == 'th') {
                if(this.region_selected == '' && this.province_selected == '') {
                    sort = this.lists.filter(i => i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery));
                } else if(this.region_selected !== '' && this.province_selected == '') {
                    sort = this.lists.filter(i => i.region_id == this.region_selected && (i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery)))
                } else if(this.region_selected == '' && this.province_selected !== '') {
                    sort = this.lists.filter(i => i.province_id == this.province_selected && (i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery)))
                } else if(this.region_selected !== '' && this.province_selected !== '') {
                    sort = this.lists.filter(i => i.region_id == this.region_selected && i.province_id == this.province_selected && (i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery)))
                }
                if(sort.length > 0) {
                    this.lists_sale = sort;
                } else {
                    Swal.fire({
                        icon: 'question',
                        title: '<strong class="display-5">Oops!</strong>',
                        html: '<strong class="display-5">ไม่พบข้อมูล</strong>',
                        confirmButtonText: 'ปิด'
                        // footer: '<a href="">Why do I have this issue?</a>'
                    })
                    this.region_selected = this.province.find(i=>i.id == this.province_selected).ref_region_id
                    this.province_selected = '';
                    this.filteredSale()
                }
            } else {
                if(this.region_selected == '' && this.province_selected == '') {
                    sort = this.lists.filter(i => i.name_en.includes(this.searchQuery) || i.address_en.includes(this.searchQuery));
                } else if(this.region_selected !== '' && this.province_selected == '') {
                    sort = this.lists.filter(i => i.region_id == this.region_selected && (i.name_en.includes(this.searchQuery) || i.address_en.includes(this.searchQuery)))
                } else if(this.region_selected == '' && this.province_selected !== '') {
                    sort = this.lists.filter(i => i.province_id == this.province_selected && (i.name_en.includes(this.searchQuery) || i.address_en.includes(this.searchQuery)))
                } else if(this.region_selected !== '' && this.province_selected !== '') {
                    sort = this.lists.filter(i => i.region_id == this.region_selected && i.province_id == this.province_selected && (i.name_en.includes(this.searchQuery) || i.address_en.includes(this.searchQuery)))
                }
                if(sort.length > 0) {
                    this.lists_sale = sort;
                } else {
                    Swal.fire({
                        icon: 'question',
                        title: this.$t("search.title"),
                        text: this.$t("search.notFoundDistribution"),
                        // footer: '<a href="">Why do I have this issue?</a>'
                    })
                    this.region_selected = this.province.find(i=>i.id == this.province_selected).ref_region_id
                    this.province_selected = '';
                    this.filteredSale()
                }
            }
            
            
            // return this.lists.filter(i => i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery))
        },
    },
    // computed: {
    //     filteredSale() {
    //         if(this.region_selected == '' && this.province_selected == '') {
    //             return this.lists.filter(i => i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery));
    //         } else if(this.region_selected !== '' && this.province_selected == '') {
    //             return this.lists.filter(i => i.region_id == this.region_selected && (i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery)))
    //         } else if(this.region_selected == '' && this.province_selected !== '') {
    //             return this.lists.filter(i => i.province_id == this.province_selected && (i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery)))
    //         } else if(this.region_selected !== '' && this.province_selected !== '') {
    //             return this.lists.filter(i => i.region_id == this.region_selected && i.province_id == this.province_selected && (i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery)))
    //         }
    //         // return this.lists.filter(i => i.name.includes(this.searchQuery) || i.address.includes(this.searchQuery))
    //     },
    // },
    mounted() {
        this.fetchMaster()
        this.fetchSearch()
        document.title = "AGC - Sale";

        AOS.init();
        resize();
        function resize() {
            if (window.innerWidth < 800) $('.footer').css({ 'position': 'relative', 'top': '0px' });
        }
    },
    components: {
        Footer, Header, Breadcrumb,Toolbar
    }
};